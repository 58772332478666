import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/CancelRounded';
import StripeCheckoutForm from './StripeCheckoutForm'; // Assuming you have a CheckoutForm component for handling the Stripe form
import styles from './StripeCheckout.module.css';
import { createStripeCheckoutSession, getStripeClientSecret, getCurrentOrder } from '../../redux/ordersSlice';

const stripePromise = loadStripe('pk_test_51Pc3egRsjjRlJYdYL0wkAcWTsJraPD3UBlrDeiPh3G9wVpqRNSam0j4lbcDrQE0WjwphDx2ZHIPt3mLvKMxm03af00XiS6s24q');
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function StripeCheckout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientSecret = useSelector(getStripeClientSecret);
  const currentOrder = useSelector(getCurrentOrder);

  useEffect(() => {
    const data = {
      orderId: currentOrder.id,
      amount: currentOrder.totalPrice, // The amount in minor unit to be charged
      currencyCode: currentOrder.currency.code, // three letters code
    };
    dispatch(createStripeCheckoutSession(data));
  }, [dispatch]);

  const options = {
    clientSecret,
  };

  const navigateToProducts = () => {
    navigate('/');
  };

  return (
    <div className={styles.pageContainer}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'var(--tg-theme-section-bg-color)',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          height: '56px',
          borderRadius: '0 0 26px 26px',
        }}
      >
        <Toolbar sx={{ height: '56px' }}>
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
          <IconButton onClick={navigateToProducts}>
            <CancelIcon className={styles.headerIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.header}>
        <h1>Payments</h1>
      </div>
      <div className={styles.sectionContainer}>
        <Elements stripe={stripePromise} options={clientSecret ? options : {}}>
          <StripeCheckoutForm />
        </Elements>
      </div>
    </div>
  );
}

export default StripeCheckout;
