import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Typography } from "@mui/material";
import styles from "./ProductCard.module.css";
import { noImageURL } from "../../api/config";
import AddProductButton from "../AddProductButton/AddProductButton";

import "swiper/css";
import "swiper/css/pagination";

function ProductCard({ product }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Link to={`/${product.id}`} key={product.id} className={styles.link}>
      <div className={styles.productCard}>
        {/* <img src={product.imageUrl || noImageURL} alt={product.title} /> */}
        <div className={styles.imageContainer}>
          <Swiper pagination modules={[Pagination]}>
            <SwiperSlide>
              <img
                src={
                  imageLoaded && product.imageUrl
                    ? product.imageUrl
                    : noImageURL
                }
                alt={product.title}
                onLoad={handleImageLoad}
              />
            </SwiperSlide>

            {product.mediaFiles?.map((mediaFile) => (
              <SwiperSlide key={product.id + mediaFile.fileUrl}>
                <img
                  src={mediaFile.fileUrl || noImageURL}
                  alt={product.title}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.productDetails}>
          {/* <h2>{product.name}</h2> */}
          <Typography className={styles.description} variant="body2">
            {product.summary}
          </Typography>
          <Typography variant="h7" className={styles.price}>
            {`${product.price} ${product.currencySymbol}`}
          </Typography>
        </div>
        <AddProductButton product={product} />
      </div>
    </Link>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currencySymbol: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductCard;
