import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  SwipeableDrawer,
  Box,
  // Tabs,
  // Tab,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOnRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import DoneIcon from '@mui/icons-material/DoneOutlineRounded';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts, selectAllProducts, getProductsStatus } from '../../redux/productsSlice';
import { selectDefaultShipping } from '../../redux/shippingInfoSlice';
import { getStartParamUsing } from '../../redux/startConfigSlice';
import { getTotalCartItemCount } from '../../redux/cartSlice';
import {
  getAddingOrderStatus,
  getNotifyOrderStatus,
  resetAddOrderStatus,
} from '../../redux/ordersSlice';
import {
  selectedPaymentMethod,
} from '../../redux/paymentMethodsSlice';
import useTelegram from '../../hooks/useTelegram';
import ProductCard from './ProductCard';
import Skeleton from './Skeleton';
import styles from './ProductList.module.css';
// import Search from '../Search/Search';
import Shipping from '../Shipping/Shipping';

function ProductList() {
  // const [setSearchTerm] = useState('');
  // const [selectedTab, setSelectedTab] = useState(0); // Initialize with the first tab
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const defaultShipping = useSelector(selectDefaultShipping);
  const isStartParamUsed = useSelector(getStartParamUsing);
  const products = useSelector(selectAllProducts);
  const productsLoadingStatus = useSelector(getProductsStatus);
  const totalCartItemCount = useSelector(getTotalCartItemCount);
  const addingOrderStatus = useSelector(getAddingOrderStatus);
  const notifyOrderStatus = useSelector(getNotifyOrderStatus);
  const currentPaymentMethod = useSelector(selectedPaymentMethod);
  const dispatch = useDispatch();
  const {
    startParam,
    productId,
    shopId,
  } = useTelegram();

  useEffect(() => {
    if (productsLoadingStatus === 'idle' && (!productId || isStartParamUsed)) {
      dispatch(fetchProducts(shopId));
    }
  }, [productsLoadingStatus, startParam, shopId, productId, isStartParamUsed]);

  useEffect(() => {
    if (productId && !isStartParamUsed) {
      navigate(`/${productId}`);
    }
  }, [productId, navigate, isStartParamUsed]);

  const toggleDrawer = (open) => (event) => {
    if (event
      && event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  const handleAddressClick = () => {
    setDrawerOpen(true);
  };
  // const handleSearch = (term) => {
  //   setSearchTerm(term);
  // };
  // const categories = [
  //   { id: 0, name: 'All' },
  //   { id: 1, name: 'NFT' },
  //   { id: 2, name: 'Avatar' },
  //   { id: 3, name: 'Cryptocurrency' },
  //   { id: 4, name: 'Nothing' },
  //   // Add more categories
  // ];
  // const handleTabChange = (event, newIndex) => {
  //   setSelectedTab(newIndex);
  // };
  // const filteredProducts = selectedTab === 0
  //   ? products
  //   : products.filter((product) => product.categoryId === categories[selectedTab].id);

  const filteredProducts = products;

  const navigateToCart = () => {
    navigate('/cart');
  };
  const navigateToOrders = () => {
    navigate('/orders');
  };

  const handleAddOrderStatusClose = useCallback(async () => {
    dispatch(resetAddOrderStatus());
    // dispatch(clearCart());
    // dispatch(clearSelectedMethod());
    // dispatch(clearOrders());
    // navigate('/');
  }, [dispatch]);

  const getOrderBadgeContent = () => (
    notifyOrderStatus === 'succeeded' ? 1 : 0
  );

  const Puller = styled(Box)(() => ({
    width: 40,
    height: 6,
    backgroundColor: 'var(--tg-theme-hint-color)',
    borderRadius: 3,
    // position: 'absolute',
    top: 8,
    // left: 'calc(50% - 20px)',
  }));

  if (productsLoadingStatus === 'loading') {
    return <Skeleton />;
  }

  if (!products || !products.length) {
    return (
      <div className={styles.productListContainer}>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: 'var(--tg-theme-section-bg-color)',
            boxShadow: 'none',
            height: '56px',
            borderRadius: '0 0 26px 26px',
          }}
        >
          <Toolbar sx={{ height: '56px' }}>
            <IconButton onClick={toggleDrawer(true)}>
              <LocationOnIcon className={styles.locationIcon} onClick={handleAddressClick} />
            </IconButton>
            <Typography
              variant="body1"
              onClick={handleAddressClick}
              sx={{ cursor: 'pointer', color: 'var(--tg-theme-text-color)' }}
            >
              {defaultShipping?.address1 || '...'}
            </Typography>
            <div style={{ flexGrow: 1 }} />
            {/* Add flexGrow to push next icon to the right */}
            <IconButton onClick={navigateToOrders}>
              <TimelineRoundedIcon className={styles.headerIcon} />
            </IconButton>
            <IconButton onClick={navigateToCart}>
              <Badge badgeContent={totalCartItemCount} color="error">
                <ShoppingCartRoundedIcon className={styles.headerIcon} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Typography variant="h6" sx={{ margin: '0px', padding: '150px 0px', color: 'var(--tg-theme-destructive-text-color)' }}>Products not found</Typography>
      </div>
    );
  }

  return (
    <div className={styles.productListContainer}>
      <AppBar
        position="fixed"
        sx={{
          // backgroundColor: 'transparent',
          // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          // backdropFilter: 'blur(42px)', // Apply a blur effect
          // WebkitBackdropFilter: 'blur(42px)', // For Safari support
          backgroundColor: 'var(--tg-theme-section-bg-color)',
          // boxShadow: 'none',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          height: '56px',
          borderRadius: '0 0 26px 26px',
        }}
      >
        <Toolbar sx={{ height: '56px' }}>
          <IconButton onClick={toggleDrawer(true)}>
            <LocationOnIcon className={styles.locationIcon} onClick={handleAddressClick} />
          </IconButton>
          <Typography
            variant="body1"
            onClick={handleAddressClick}
            sx={{ cursor: 'pointer', color: 'var(--tg-theme-text-color)' }}
          >
            {defaultShipping?.address1 || '...'}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
          <IconButton onClick={navigateToOrders}>
            <Badge badgeContent={getOrderBadgeContent()} color="error" variant="dot">
              <TimelineRoundedIcon className={styles.headerIcon} />
            </Badge>
          </IconButton>
          <IconButton onClick={navigateToCart}>
            <Badge badgeContent={totalCartItemCount} color="error">
              <ShoppingCartRoundedIcon className={styles.headerIcon} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <Search onSearch={handleSearch} /> */}
      {/* <CategoryTabs
        categories={categories}
        activeCategory={activeCategory}
        onChangeCategory={(categoryId) => setActiveCategory(categoryId)}
      /> */}
      {/* <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
      >
        {categories.map((category) => (
          <Tab label={category.name} value={category.id} />
        ))}
      </Tabs> */}
      <div className={styles.productList}>
        {filteredProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <Shipping
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={addingOrderStatus === 'succeeded' && currentPaymentMethod.code !== 'Crypto'}
        onClose={handleAddOrderStatusClose}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            backgroundColor: 'var(--tg-theme-secondary-bg-color)',
            border: '1px 0px 0px 0px',
            padding: '0px 0px 32px 0px',
          },
        }}
      >
        <div className={styles.pullerWrapper}>
          <Puller />
        </div>
        <div className={styles.successWrapper}>
          <DoneIcon className={styles.iconSuccess} style={{ fontSize: 40 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: 'var(--tg-theme-section-header-text-color)' }}
          >
            Order created successfully
          </Typography>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default ProductList;
