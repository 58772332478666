import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Box, Typography,
} from '@mui/material';
import {
  CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import {
  getStripeClientSecret,
  getCurrentOrder,
} from '../../redux/ordersSlice';
import styles from './StripeCheckoutForm.module.css';

function StripeCheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sessionError, setSessionError] = useState(null);

  const clientSecret = useSelector(getStripeClientSecret);
  const currentOrder = useSelector(getCurrentOrder);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSessionError(null);

    if (!stripe || !elements) {
      setSessionError('Stripe has not loaded yet.');
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setSessionError('Card Element not found.');
      setLoading(false);
      return;
    }

    if (!clientSecret) {
      setSessionError('Client session is not available.');
      setLoading(false);
      return;
    }

    const {
      error: stripeError,
    //   paymentIntent,
    } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    // const { error: stripeError } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });

    if (stripeError) {
      setSessionError(stripeError.message);
      setLoading(false);
      return;
    }

    // Payment succeeded, handle accordingly
    // handlePaymentSuccess(paymentIntent);
    // You can dispatch an action or redirect the user to a success page
    // console.log('Payment succeeded:', paymentIntent);
    setLoading(false);

    navigate('/');
  };

  //   const handlePaymentSuccess = (paymentIntent) => {
  //     // Dispatch an action, update UI state, or navigate to a success page
  //     console.log('Payment succeeded:', paymentIntent);

  //     // Example: Dispatch an action to update order status or notify the user
  //     dispatch(updateOrderStatus(paymentIntent));

  //     // Example: Show a success message to the user
  //     alert('Payment succeeded!');

  //     // Example: Redirect to a success page
  //     // history.push('/success'); // need to import useHistory from 'react-router-dom' for this
  //   };

  return (
    <Box className={styles.container}>
      <Typography variant="h5" gutterBottom className={styles.header}>
        Checkout
      </Typography>
      {currentOrder && (
        <Box className={styles.orderSummary}>
          <Typography variant="body1">
            Amount:
          </Typography>
          <Typography variant="body1">
            {currentOrder.totalPrice}
            {' '}
            {currentOrder.currency.symbol}
          </Typography>
        </Box>
      )}
      {sessionError && <Typography color="error" className={styles.errorMessage}>{sessionError}</Typography>}
      <form onSubmit={handleSubmit}>
        <CardElement className={styles.cardElement} />
        <Button
          type="submit"
          disabled={!stripe || loading}
          variant="contained"
          sx={
            {
              width: '95%',
              height: '50px',
              fontSize: '16px',
              backgroundColor: 'var(--tg-theme-button-color)',
              color: 'var(--tg-theme-button-text-color)',
              borderRadius: '25px',
            }
          }
          className={`${styles.button} ${loading ? styles.processing : ''}`}
        >
          {loading ? 'Processing...' : 'Pay'}
        </Button>
      </form>
    </Box>
  );
}

export default StripeCheckoutForm;
